import React from "react";
import { graphql } from "gatsby";

import Helmet from "react-helmet";
import cx from "classnames";
import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";
import { StickyContainer, Sticky } from "react-sticky";

import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import siteConfig from "../../data/SiteConfig";
import { getPerformerSortName } from "../utils/strings";
import { FONT_FAMILIES, COLORS } from "../utils/theme";

import Page from "../components/Layout/Page";
import {
  FixedWidthContainer,
  FullWidthContainer
} from "../components/Layout/ContentContainers";
import { StyledComponentsBreakpoints } from "../components/Layout/Responsive";

import PerformerList from "../components/PerformerList";

const iconColor = COLORS.red.rgba.replace(", 1)", ", 0.6)");

class BandsPage extends React.Component {
  constructor(props) {
    super(props);

    const performers = this.props.data.allFestPerformers.edges;

    const queryablePerformers = performers
      .sort((a, b) => {
        const aName = a.node.title.rendered;
        const bName = b.node.title.rendered;
        if (aName === bName) {
          return 0;
        }

        return getPerformerSortName(aName) > getPerformerSortName(bName)
          ? 1
          : -1;
      })
      .map(edge => {
        return {
          node: Object.assign(edge.node, {
            searchableText: edge.node.title.rendered.toLowerCase()
          })
        };
      });

    this.state = {
      performers: queryablePerformers,
      performerNameSearchInputValue: "",
      internationalFilterValue: false,
      floridaExclusiveFilterValue: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }
  getMetaUrl() {
    return (
      `${siteConfig.siteUrl}${this.props.location.pathname}` ||
      siteConfig.siteUrl
    );
  }
  getMetaTitle() {
    const pageTitle = `Bands | Lineup | ${siteConfig.siteTitle}`;

    return pageTitle;
  }
  handleInputChange(event) {
    const searchTerm = event.target.value;

    this.setState({
      performerNameSearchInputValue: searchTerm
    });
  }
  handleCheckboxChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  render() {
    const filteredPerformers = this.state.performers
      .filter(performer => {
        if (
          performer.node.title.rendered.toLowerCase() === "placeholder band"
        ) {
          return false;
        }
        if (
          this.state.internationalFilterValue === true &&
          performer.node.acf.international !== "1"
        ) {
          return false;
        }
        if (
          this.state.floridaExclusiveFilterValue === true &&
          performer.node.acf.florida_exclusive !== "1"
        ) {
          return false;
        }

        if (
          this.state.performerNameSearchInputValue.length > 0 &&
          performer.node.searchableText.indexOf(
            this.state.performerNameSearchInputValue.toLowerCase()
          ) === -1
        ) {
          return false;
        }

        return true;
      })
      .sort((a, b) => a.node.acf.tier - b.node.acf.tier);
    return (
      <Page title="Bands" showTitle={false}>
        <Helmet title={this.getMetaTitle()}>
          <meta property="og:url" content={this.getMetaUrl()} />
          <meta property="og:title" content={this.getMetaTitle()} />
        </Helmet>

        <FixedWidthContainer>
          <h1>Band Lineup</h1>
        </FixedWidthContainer>

        <FullWidthContainer>
          <StickyContainer>
            <Sticky>
              {({ isSticky, style }) => (
                <StickyToolbar
                  style={style}
                  className={cx({ "sticky-active": isSticky })}
                >
                  <SearchFieldContainer>
                    <Input
                      id="name"
                      placeholder="Search band names"
                      inputProps={{
                        "aria-label": "Search band names"
                      }}
                      value={this.state.performerNameSearchInputValue}
                      onChange={this.handleInputChange}
                      fullWidth
                    />
                  </SearchFieldContainer>
                  <FilterContainer>
                    <CheckboxLabel>
                      <Checkbox
                        checked={this.state.internationalFilterValue}
                        onChange={this.handleCheckboxChange(
                          "internationalFilterValue"
                        )}
                        value="internationalFilterValue"
                      />
                      <FontAwesomeIcon
                        icon={["fas", "globe-americas"]}
                        color={iconColor}
                      />
                      {` International`}
                    </CheckboxLabel>

                    <CheckboxLabel>
                      <Checkbox
                        checked={this.state.floridaExclusiveFilterValue}
                        onChange={this.handleCheckboxChange(
                          "floridaExclusiveFilterValue"
                        )}
                        value="floridaExclusiveFilterValue"
                      />
                      <FontAwesomeIcon
                        icon={["fas", "id-badge"]}
                        color={iconColor}
                      />
                      {` FL Exclusive Set`}
                    </CheckboxLabel>
                  </FilterContainer>
                </StickyToolbar>
              )}
            </Sticky>
            <PerformerListContainer>
              <PerformerList
                performers={filteredPerformers}
                urlPrefix="bands"
              />
            </PerformerListContainer>
          </StickyContainer>
        </FullWidthContainer>
      </Page>
    );
  }
}

export default BandsPage;

const fadeInAnimation = keyframes`${fadeIn}`;

const StickyToolbar = styled.div`


  background: ${COLORS.black.rgba};

  left: 0;
  z-index: 1100;
  min-height: 72px;
  line-height: 72px;
  margin: 0 auto 24px;
  text-align: center;

  &.sticky-active {
    box-shadow: 0px 0px 1px 3px rgba(137, 137, 137, 0.3);
  }
  label {
    cursor: pointer;
    margin: 0 8px;

    > span {
      height: 36px;
      width: 36px;
    }

    ${StyledComponentsBreakpoints.Phone`
      font-size: 0.75rem;
      margin: 0 6px 0 0;

      > span {
        font-size: 0.6rem;
        height: 24px;
        width: 24px;
      }
    `};
  }

  ${StyledComponentsBreakpoints.Phone`
    top: unset !important;
    bottom: 60px !important;
    margin-bottom: 0 !important;
  `};
`;
const CheckboxLabel = styled.label`
  ${FONT_FAMILIES.headline};
  font-size: 18px;
`;

const SearchFieldContainer = styled.div`
  animation: 0.420s ${fadeInAnimation};
  display: inline-block;
  width: 280px;
  max-width: 95%;
  padding-left: 1rem;
  margin-right: 1rem;
  color: #fff;
  input { 
    color: #fff;
  }
  @media (max-width: 840px) {
    width: 220px;
  }

  ${StyledComponentsBreakpoints.Phone`
    display: block;
    line-height: 0;
    width: 100%;
    margin: 1rem 0 0;
    
    text-align: left;
  `};
`;
const FilterContainer = styled.div`
  animation: 0.420s ${fadeInAnimation};
  display: inline-block;
  ${StyledComponentsBreakpoints.Phone`
    display: block;
    line-height: 0;
    margin: 1rem 0;
  `};
`;

const PerformerListContainer = styled.div`
  margin-bottom: 64px;

  ${StyledComponentsBreakpoints.Phone`
    max-width: 95%;
    margin: 0 auto 24px;
  `};
`;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BandsPageQuery {
    allFestPerformers(filter: { acf: { type: { eq: "band" } } }) {
      edges {
        node {
          id
          festId
          title {
            rendered
          }
          slug
          acf {
            tier
            hometown
            florida_exclusive
            international
            photo {
              url
              sizes {
                thumbnail
                thumbnail_width
                thumbnail_height
                medium
                medium_width
                medium_height
              }
            }
          }
        }
      }
    }
  }
`;
